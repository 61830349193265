<template>
  <div id="msgList" class="flex flex-wrap">
    <template v-for="(item, index) in babyList">
      <div class="item" :key="index">
        <div class="issuer flex align-center" >
            <el-image class="logo" fit="fill" :src="item.createLogo" />
            <div class="f18 ml18">{{ item.createBy }}</div>
          </div>
        <div class="logo_pic" @click="jumpDetail(item)">
          <!-- <div class="issuer flex align-center" >
            <el-image class="logo" fit="fill" :src="item.createLogo" />
            <div class="f18 ml18">{{ item.createBy }}</div>
          </div> -->
          <div class="flex mb10 babyEnclosureList flex-wrap">
            <el-image :src="item.babyEnclosureList[0].url" class="sitem_0" fit="fill" />
          </div>
          <!-- <div class="text-left mb5 f17 text-overflow2" style="margin-left:-10%;">
            {{ item.content }}
          </div> -->
        </div>
         <div class="text-left mb5 f17 text-overflow2" >
            {{ item.content }}
          </div>
        <div class="info">
          <div class="time mb10 flex justify-between">
            <div>{{ item.createTime | dataFormat }}</div>
            <div>{{ item.viewCount }}人浏览</div>
          </div>
          <div class="support flex justify-between" >
            <div v-if="item.like !== true">
              <svg class="icon" aria-hidden="true"   @click="saveBaby(item)" style="float:left">
                <use xlink:href="#icon-dianzan"></use>
              </svg>
              <div style="float:left;margin-left:5px">{{item.likeCount}}</div>
              
            </div>
            <div v-else>
              <svg class="icon" aria-hidden="true" style="color: #db3b3d;float:left" @click="saveBaby(item)" >
                <use xlink:href="#icon-dianzan_active"></use>
              </svg>
              <div style="float:left;margin-left:5px">{{item.likeCount}}</div>
            </div>
            <!-- <div class="action_text">{{item.likeCount}}</div> -->

            <!-- <div class="flex align-center" @click="copy">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-fenxiang1"></use>
              </svg>
              <div class="ml5" >分享</div>
            </div> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    babyList: {
      type: Array,
      default() {
        return {};
      },
      require: true,
    },
  },
  data() {
    return {
      
    };
  },
  created(){
  },
  methods: {
    jumpDetail(item) {
      var getData = {}
      getData.id = item.id
      getData.viewCount = item.viewCount + 1
      this.$api.partyBuild.view(getData).then((res) => {
        if (res.data.code === 200) {
          console.log("浏览加一")
        }
      });
      //详情
      this.$router.push({
        path: "/partyBuilding/detail",
        query: {
          id: item.id,
        },
      });
    },
    //分享
    copy () {
      let invitelink = location.href;
      this.$copyText(invitelink).then(
        res => {
          this.$notify({
          title:'已复制到剪切板了',
          message:'分享给您的好友',
          type:'seccess',
          duration:2000
        })
        },
        err => {
          console.log(err) // 同上
        }
      )
    },
    //点赞
    saveBaby(item) {
      var getData = {};
      getData.babyCode = item.babyCode;
      getData.memberCode = JSON.parse(sessionStorage.getItem("userInfo")).memberCode;
      this.$api.partyBuild.likeBaby(getData).then((res) => {
        if (res.data.code === 200) {
          console.log("恭喜你，点赞成功啦！",res.data);
          // this.isUp=true
        }
      });
      if (item.like == true) {
        item.like = false;    
        item.likeCount--;
      } else {   
         item.like = true;
        item.likeCount++;
      }
    },
  },
};

</script>

<style lang="less" scoped>
#msgList {
  padding: 0 30px;
}
.item:hover{
  cursor: pointer;
}
.item {
  display: flex;
  flex-direction: column;
  width: 250px;
  // height: 100px;
  padding: 14px 15px;
  margin-right: 15px;
  margin-bottom: 20px;
  border-radius: 5px;
  justify-content: space-between;
  background: white;
  .issuer {
    margin-bottom: 14px;
    padding: 0;
    .logo {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
    .emptyBox {
    height: 10px;
    width: 100%;
    background: white;
  }
  .logo_pic {
    padding: 10px 20px 0;
    
  }
  img {
    width: 100%;
  }
  .support {
    margin-left: auto;
    .icon {
      font-size: 22px;
    }
    .icon:hover{
      cursor: pointer;
    }
  }
  .sitem_0 {
    width: 381px;
    height: 130px;
    border-radius: 5px;
  }
  .sitem_1 {
    width: 383px;
    height: 383px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_2 {
    width: 383px;
    height: 192px;
    border-radius: 5px;
    overflow: hidden;
  }
  & .sitem_2:first-child {
    margin-bottom: 5px;
  }
  .sitem_3 {
    width: 383px;
    height: 118px;
    border-radius: 5px;
    overflow: hidden;
  }
  & .sitem_3:nth-child(2) {
    margin: 5px 0;
  }
  .sitem_4 {
    width: 175px;
    height: 175px;
    margin: 2.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_5 {
    width: 120px;
    height: 175px;
    margin: 2.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_6 {
    width: 120px;
    height: 175px;
    margin: 2.5px;
    border-radius: 5px;
    overflow: hidden;
  }

  .sitem_7 {
    width: 118px;
    height: 118px;
    margin: 2.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_8 {
    width: 118px;
    height: 118px;
    margin: 2.5px;
    border-radius: 5px;
    overflow: hidden;
  }
  .sitem_9 {
    width: 118px;
    height: 118px;
    margin: 2.5px;
    border-radius: 5px;
    overflow: hidden;
  }
}
</style>
