<template>
  <transition name="fade" appear>
    <div id="partyBuilding">
      <section class="babyList" v-infinite-scroll="load" infinite-scroll-disabled="disabled">
        <MsgList :babyList="babyList" />
        <div v-if="loading">加载中...</div>
        <div v-if="noMore" style="width: 100%">没有更多了</div>
      </section>
      <div class="floatBox" @click="goDeclare">发布</div>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
import MsgList from "./components/MsgList.vue";
export default {
  components: {
    MsgList,
  },
  data() {
    return {
      babyList: [],
      total: 0,
      loading: false,
      currPage: 1,
      // 每页显示多少数据
      pageSize: 12,
      count: 0,
      pageCount: 0,
      condition: {
        status: "",
      },
    };
  },

  computed: {
    noMore() {
      return this.pageSize >= this.count;
    },
    disabled() {
      return this.loading || this.noMore;
    },
  },
  methods: {
    load() {
      this.loading = true;
      this.pageSize+=12;
      this.getBabyList();
    },
    goDeclare() {
      this.$router.push({
        path: "/partyBuilding/more",
      });
    },
    jumpDetail(item) {
      //详情
      this.$router.push({
        path: "/partyBuilding/detail",
        query: { id: item.id },
      });
    },
    // 宝宝列表
    getBabyList() {
      var param = {}
      var condition = {}
      var a = sessionStorage.getItem("userInfo")
      if(a!==null){
        param.code = JSON.parse(sessionStorage.getItem("userInfo")).memberCode
      }else{
        param.code = '000'
      }
      param.pageSize=this.pageSize
      param.currPage= this.currPage
      condition.status = 1
      param.condition = condition
      this.$api.partyBuild.getBabyList(param).then((res) => {
        if (res.data.code === 200) {
          this.loading = false;
          this.babyList = res.data.data.data;
          this.pageCount = res.data.data.pageCount;
          this.count = res.data.data.count;
        }
      })
    },
  },
  beforeCreate() {
    this.$nextTick(() => {
      document.querySelector("#index").setAttribute("style", "background:#eeeeee");
    });
  },
  beforeDestroy() {
    document.querySelector("#index").setAttribute("style", "background:#ffffff");
  },
  created() {
    this.$store.commit(CHANGE_NAVINDEX, "2");
    this.getBabyList();
  },
};
</script>

<style lang="less" scoped>
#partyBuilding {
  padding-top: 30px;
  position: relative;
}
.babyList {
  height: 710px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px; /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    background-color: skyblue;
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
  }
  &::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ededed;
    border-radius: 10px;
  }
}
.floatBox {
  position: absolute;
  right: 0%;
  top: 40%;
  width: 75px;
  height: 75px;
  color: white;
  border-radius: 50%;
  background: #f5af01;
  font-size: 29px;
  line-height:75px;
  text-align: center;
  overflow: hidden;
  transition: all 0.2s;
}
.floatBox:hover {
  cursor: pointer;
  transform: scale(1.1);
}
</style>
